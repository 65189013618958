<template>
  <div id="sanciones_dialog">
    <v-dialog
      scrollable
      v-model="showSancionDialog"
      content-class="dialog-task-detail"
      v-if="selected"
    >
      <v-form ref="sancionForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>Sancion</v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-autocomplete
                  v-model="selected.empleado"
                  label="Empleado"
                  placeholder="Empleado"
                  item-text="fullname"
                  item-value="id"
                  :items="users_data"
                  hide-no-data
                  hide-selected
                  required
                  :rules="requiredRules"
                ></v-autocomplete
              ></v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-menu
                  ref="fecha_alta_menu"
                  v-model="select_fecha_alta_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.fecha_alta"
                      label="Fecha alta"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.fecha_alta"
                    no-title
                    @input="select_fecha_alta_menu = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    readonly
                    disabled
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-menu
                  ref="startDate_menu"
                  v-model="select_startDate_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.fecha_suceso"
                      label="Fecha suceso"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.fecha_suceso"
                    no-title
                    @input="select_startDate_menu = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-autocomplete
                  v-model="selected.categoria"
                  label="Categoria"
                  placeholder="Categoria"
                  item-text="value"
                  item-value="key"
                  :items="CATEGORIA_TYPE"
                  hide-no-data
                  hide-selected
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <v-divider
              style="background-color: #156082 !important; border-color: #156082 !important;"
              :thickness="5"
              class="border-opacity-100 primary"
            ></v-divider>
            <v-row align="center">
              <v-col cols="12" sm="12" md="12" lg="12" class="text-3xl"
                ><span class="text-3xl">Descripción hechos</span></v-col
              >
              <v-col cols="12" sm="10" md="10" lg="10" class="mx-auto">
                <v-textarea
                  label="Descripción hechos"
                  row-height="10"
                  rows="5"
                  outlined
                  v-model="selected.descripcion"
                ></v-textarea>
              </v-col>
            </v-row>
            <div v-if="selected?.sancionFirmado?.length">
              <v-row
                v-for="(el, index) in selected.sancionFirmado"
                :key="index"
              >
                <v-col cols="12" sm="8" md="8" lg="8" class="text-center">{{
                  el.id ? el.file : el.name
                }}</v-col>
                <v-col cols="12" sm="1" md="1" lg="1"
                  ><v-icon
                    color="primary"
                    v-if="el.id"
                    @click="downloadFile(el)"
                  >
                    mdi-download
                  </v-icon></v-col
                >
                <v-col cols="12" sm="1" md="1" lg="1"
                  ><v-icon color="red" @click="deleteFile(index)">
                    mdi-delete
                  </v-icon></v-col
                >
              </v-row>
            </div>
            <v-row align="center" class="items-center">
              <v-col
                cols="12"
                sm="4"
                md="4"
                lg="4"
                align="center"
                class="flex mx-auto"
              >
                <v-file-input
                  label="Upload file"
                  filled
                  hide-input
                  prepend-icon="mdi-upload"
                  class="justify-center"
                  multiple
                  @change="onAddFile"
                ></v-file-input>
                Subir Fichero</v-col
              ></v-row
            >
            <v-divider
              style="background-color: #156082 !important; border-color: #156082 !important;"
              :thickness="5"
              class="border-opacity-100 primary my-8"
            ></v-divider>
            <v-row align="center">
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-autocomplete
                  v-model="selected.gravedad"
                  label="Gravedad"
                  placeholder="Gravedad"
                  item-text="value"
                  item-value="key"
                  :items="GRAVEDAD_TYPE"
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6"
                ><v-autocomplete
                  v-model="selected.status"
                  label="Status"
                  placeholder="Status"
                  item-text="value"
                  item-value="key"
                  :items="STATUS_TYPE"
                  hide-no-data
                  hide-selected
                ></v-autocomplete
              ></v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  v-model="selected.sancion"
                  label="Sanción"
                  placeholder="Sanción"
                  item-text="value"
                  item-value="key"
                  :items="SANCION_TYPE"
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-autocomplete
                  v-model="selected.comunicacion"
                  label="Comunicación"
                  placeholder="Comunicación"
                  item-text="value"
                  item-value="key"
                  :items="COMMUNICATION_TYPE"
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                lg="3"
                v-if="selected.comunicacion != 'no_comunicada'"
              >
                <v-menu
                  ref="startDate_comunicaion_menu"
                  v-model="select_startDate_comunicaion_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.fecha_comunicaion"
                      label="Fecha Comunicación"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.fecha_comunicaion"
                    no-title
                    @input="select_startDate_comunicaion_menu = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" class="pl-8">Comentarios RRHH</v-col>
              <v-col cols="12" sm="9" md="9" lg="9" class="mx-auto">
                <v-textarea
                  label="Comentarios RRHH"
                  row-height="5"
                  rows="4"
                  outlined
                  v-model="selected.comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black darken-1" text @click="closeTaskDetail">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import { mapGetters } from "vuex";
import download from "js-file-download";

export default {
  name: "SancionesDialog",
  props: ["showSancion", "selectedTaskId", "selected", "users_data"],
  data: function() {
    return {
      loadingInstance: null,
      taskId: null,
      selectedTask: null,

      showEdit: false,
      editOpts: null,

      /******************* Task Detail ****************/

      hasChanged: false, //to track edition

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      fileRules: file => {
        // if (file) {
        //   if (file.size <= 5e8) {
        //     return true;
        //   } else {
        //     return "File size should be less than 10 MB!";
        //   }
        // }
        return true;
      },
      select_startDate_menu: false,
      select_fecha_alta_menu: false,
      select_startDate_comunicaion_menu: false,
      showSancionDialog: false,
      containerFluid: true,
      showCommentDialog: false,
      selectedComment: null,
      isLoadingComment: false,
      valid: true,
      validEdit: true,

      GRAVEDAD_TYPE: [
        { key: "leve", value: "Leve" },
        { key: "grave", value: "Grave" },
        { key: "muyGrave", value: "Muy Grave" }
      ],

      CATEGORIA_TYPE: [
        { key: "ausentrase", value: "Ausentarse de trabajo" },
        { key: "desobediencia", value: "Desobediencia" },
        { key: "embriaguez", value: "Embriaguez o consumo de sustancias" },
        { key: "comunicacion", value: "Comunicación" },
        {
          key: "conservacion",
          value: "Conservación Material (Tablets y Material PLV)"
        },
        { key: "aseo", value: "Aseo y Limpieza" },
        { key: "ofensas", value: "Ofensas y Agresiones" },
        { key: "fraude", value: "Fraude" },
        { key: "rendimiento", value: "Rendimiento" },
        { key: "falsear", value: "Falsear Datos" },
        { key: "prevención", value: "Prevención de riesgos" },
        { key: "acumulación", value: "Acumulación" }
      ],

      COMMUNICATION_TYPE: [
        { key: "no_comunicada", value: "No comunicada" },
        { key: "verbal", value: "Verbal" },
        { key: "por_escrito", value: "Por escrito" }
      ],

      SANCION_TYPE: [
        { key: "sin", value: "Sin Sanción" },
        { key: "amonestación_verbal", value: "Amonestación verbal" },
        { key: "amonestación_escrito", value: "Amonestación por escrito" },
        {
          key: "suspensión_empleo",
          value: "Suspensión de empleo y sueldo por un día"
        },
        {
          key: "suspensión_diez",
          value: "Suspensión de empleo y sueldo de dos a diez días"
        },
        {
          key: "suspensión_meses",
          value: "Suspensión de empleo y sueldo de once días a dos meses"
        },
        { key: "despido", value: "Despido" }
      ],

      STATUS_TYPE: [
        { key: "pendiente_revision", value: "Pendiente Revisión RRHH" },
        { key: "incidencia", value: "Incidencia" },
        { key: "pendiente_evaluacion", value: "Pendiente Evaluación Gravedad" },
        { key: "pendiente_comunicacion", value: "Pendiente Comunicación" },
        { key: "finalizada", value: "Finalizada" }
      ],

      descripcion: null,
      comentarios: null
    };
  },
  watch: {
    showSancion: function(newVal, oldVal) {
      this.hasChanged = false;
      this.showSancionDialog = newVal;
    },
    showSancionDialog: function(newVal, oldVal) {
      if (!newVal) this.$emit("closeSancion", this.hasChanged);
      if (newVal) {
        this.descripcion = this.selected.descripcion;
        this.comentarios = this.selected.comentarios;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"])
  },
  methods: {
    closeTaskDetail() {
      this.showSancionDialog = false;
      this.$nextTick(() => {});
    },

    async onEditClick() {
      this.editOpts = this.selectedTask;
      this.showEdit = true;
    },

    async onSaveClick(item) {
      if (this.$refs.sancionForm.validate()) {
        this.isSaving = true;
        let body = _.omit(item, ["sancionFirmado"]);
        try {
          let ids = item.sancionFirmado?.filter(el => el.id)?.map(el => el.id);
          let sancionFirmado = item.sancionFirmado?.filter(el => !el.id);
          if (sancionFirmado && sancionFirmado.length) {
            const data = await Promise.all(
              sancionFirmado.map(el => ApiService.uploadFile(el))
            );
            ids = [...ids, ...data.map(el => el.data.id)];
          }
          if (ids && ids.length) {
            body.doc_firmado = JSON.stringify(ids);
          } else {
            body.doc_firmado = "";
          }
          let save_res = null;
          if (body.id) {
            save_res = await ApiService.put(
              `salesforce/sanciones/update/${body.id}`,
              body
            );
          } else {
            save_res = await ApiService.post(
              `salesforce/sanciones/create`,
              body
            );
          }
          if (save_res.success) {
            logInfo("Exitosa");
            this.showEdit = false;
            this.hasChanged = true;
            this.closeTaskDetail();
          } else {
            logError(save_res.error);
          }
          this.isSaving = false;
        } catch (error) {
          logError("Failed");
        }
      }
    },

    async onAddFile(file) {
      this.selected.sancionFirmado.push(...file);
    },

    async onChangeFile(file) {
      this.selected.sancionFirmado.file = file;
    },

    async downloadFiles(documents) {
      this.showloadingspinner();
      try {
        await Promise.all(
          documents.map(document => this.downloadFile(document))
        );
      } catch (error) {
      } finally {
        this.closeloadingspinner();
      }
    },

    async downloadFile(document) {
      this.showloadingspinner();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        const response = await ApiService.get(file_uri, {
          responseType: "arraybuffer"
        });
        download(response, document.file);
      }
      this.closeloadingspinner();
    },

    async deleteFile(id) {
      if (this.selected?.sancionFirmado?.length) {
        this.selected.sancionFirmado = this.selected.sancionFirmado.filter(
          (_, index) => index !== id
        );
      }
    },

    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#sanciones_dialog"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    closeloadingspinner() {
      this.loadingInstance.close();
    },

    async getDataFromApi() {},

    async init() {}
  },
  async mounted() {}
};
</script>
<style>
.dialog-task-detail {
  max-width: 800px;
  max-height: 800px;
  border: 2px solid #184e65eb;
  border-radius: 11% !important;
}
.dialog-task-detail .v-card {
  padding: 10px !important;
}
.dialog-task-detail .user-comment {
  max-height: 175px;
  margin-bottom: 10px;
}
.dialog-task-detail .user-comment .individual-comment {
  border: 1px solid #092a386e;
  margin-bottom: 3px !important;
  height: 85px;
  padding: 8px !important;
}
.user-comment-box {
  overflow-y: auto !important;
  max-height: 92% !important;
  padding-right: 2px;
}
.user-comment-box::-webkit-scrollbar {
  width: 3px;
}
.user-comment-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.user-comment-box::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.scroll-container {
  padding-left: 3px;
  direction: rtl; /* Change the content direction to right-to-left */
}
.scroll-content {
  direction: ltr; /* Keep the content direction left-to-right */
}
/* Optional: Customize the scrollbar appearance */
.scroll-container::-webkit-scrollbar {
  width: 9px;
}
.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroll-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.my-prop-label {
  margin-bottom: 2px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  width: 50px;
  margin-right: 3px;
  border: 1px solid;
  background: #e3e3e3;
}
</style>
