<template>
  <div>
    <TaskDetailDialog
      :showSancion="showSancion"
      @closeSancion="closeSancion"
      :selectedTaskId="selectedTaskId"
      :selected="selected"
      :users_data="users_data"
    ></TaskDetailDialog>

    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Sanciones</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="dialogDelete" max-width="550px">
        <v-card>
          <v-card-title class="text-h6"
            >¿Estás segura de que quieres eliminar la sanción?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="!isManager"
              color="primary"
              dark
              class="mb-2"
              @click="onItemEdit"
            >
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="sanciones_table"
              fixed-header
              max-height="calc(100vh - 130px)"
              :columns="columns"
              :table-data="sanciones"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="`2100px`"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="total_sanciones"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";
import PictureInput from "vue-picture-input";
import TaskDetailDialog from "./SancionesDialog.vue";

export default {
  name: "Sanciones",
  components: {
    PictureInput,
    TaskDetailDialog
  },
  data: function() {
    return {
      total_sanciones: 0,
      sanciones: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["name"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (rowIndex % 2 !== 0) {
            return "table-body-cell-even";
          } else {
            return "table-body-cell-odd";
          }
        }
      },
      filterCriteria: {
        name: {}
      },
      showEmpty: false,

      selected_image: null,
      showImageDialog: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: {},
      valid: true,
      requiredRules: [v => !!v || "Required"],
      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,

      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
      isSaving: false,
      users_data: null,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      GRAVEDAD_TYPE: {
        leve: "Leve",
        grave: "Grave",
        muyGrave: "Muy Grave"
      },

      CATEGORIA_TYPE: {
        ausentrase: "Ausentarse de trabajo",
        desobediencia: "Desobediencia",
        embriaguez: "Embriaguez o consumo de sustancias",
        comunicacion: "Comunicación",
        conservacion: "Conservación Material (Tablets y Material PLV)",
        aseo: "Aseo y Limpieza",
        ofensas: "Ofensas y Agresiones",
        fraude: "Fraude",
        rendimiento: "Rendimiento",
        falsear: "Falsear Datos",
        prevención: "Prevención de riesgos",
        acumulación: "Acumulación"
      },

      COMMUNICATION_TYPE: {
        no_comunicada: "No comunicada",
        verbal: "Verbal",
        por_escrito: "Por escrito"
      },

      SANCION_TYPE: {
        sin: "Sin Sanción",
        amonestación_verbal: "Amonestación verbal",
        amonestación_escrito: "Amonestación por escrito",
        suspensión_empleo: "Suspensión de empleo y sueldo por un día",
        suspensión_diez: "Suspensión de empleo y sueldo de dos a diez días",
        suspensión_meses:
          "Suspensión de empleo y sueldo de once días a dos meses",
        despido: "Despido"
      },

      STATUS_TYPE: {
        pendiente_revision: "Pendiente Revisión RRHH",
        incidencia: "Incidencia",
        pendiente_evaluacion: "Pendiente Evaluación Gravedad",
        pendiente_comunicacion: "Pendiente Comunicación",
        finalizada: "Finalizada"
      },

      /* Dialog */
      showSancion: false,
      selectedTaskId: null,

      isDownloadTemplate: false
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search(val) {
      await this.searchParent(val);
    },
    async search_projects(val) {
      this.search_brands(val);
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 35,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "fecha_alta",
          title: "Fecha alta",
          field: "fecha_alta",
          align: "left",
          width: 80,
          sortBy: ""
        },
        {
          key: "fecha_suceso",
          title: "Fecha Suceso",
          field: "fecha_suceso",
          align: "left",
          width: 80,
          sortBy: ""
        },
        {
          key: "empleado",
          title: "Empleado",
          field: "empleado",
          align: "left",
          width: 60,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {row.sancionEmpleado &&
                  row.sancionEmpleado.name + " " + row.sancionEmpleado.surname}
              </span>
            );
          }
        },
        {
          key: "responsable",
          title: "Responsable",
          field: "responsable",
          align: "left",
          width: 70,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {row.sancionResponsable &&
                  row.sancionResponsable.name +
                    " " +
                    row.sancionResponsable.surname}
              </span>
            );
          }
        },

        {
          key: "gravedad",
          title: "Gravedad",
          field: "gravedad",
          align: "left",
          width: 70,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {this.getNameFromTypes(this.GRAVEDAD_TYPE, row.gravedad)}
              </span>
            );
          }
        },

        {
          key: "categoria",
          title: "Categoría",
          field: "categoria",
          align: "left",
          width: 70,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {this.getNameFromTypes(this.CATEGORIA_TYPE, row.categoria)}
              </span>
            );
          }
        },

        {
          key: "sancion",
          title: "Sanción",
          field: "sancion",
          align: "left",
          width: 80,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {this.getNameFromTypes(this.SANCION_TYPE, row.sancion)}
              </span>
            );
          }
        },

        {
          key: "comunicacion",
          title: "Comunicación",
          field: "comunicacion",
          align: "left",
          width: 80,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {this.getNameFromTypes(
                  this.COMMUNICATION_TYPE,
                  row.comunicacion
                )}
              </span>
            );
          }
        },

        {
          key: "fecha_comunicaion",
          title: "Fecha comunicación",
          field: "fecha_comunicaion",
          align: "left",
          width: 100,
          sortBy: ""
        },
        {
          key: "status",
          title: "Status",
          field: "status",
          align: "left",
          width: 100,
          sortBy: "",
          renderBodyCell: ({ row }, h) => {
            return (
              <span>{this.getNameFromTypes(this.STATUS_TYPE, row.status)}</span>
            );
          }
        },

        {
          key: "comentarios",
          title: "Comentarios RRHH",
          field: "comentarios",
          align: "left",
          width: 100
        },

        {
          key: "documento_sancion",
          title: "Documento Sanción",
          field: "documento_sancion",
          align: "left",
          width: 80,
          renderBodyCell: ({ row }, h) => {
            return (
              <div class="text-center">
                <v-btn
                  color="primary"
                  dark
                  fab
                  on-click={() => this.downloadDocTemplate(row.id)}
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        },

        // {
        //   key: "doc_firmado",
        //   title: "Subir Doc Firmado",
        //   field: "doc_firmado",
        //   align: "left",
        //   width: 180,
        //   sortBy: "",
        //   renderBodyCell: ({ row }, h) => {
        //     return (
        //       <span>
        //         {row.sancionFirmado
        //           ?.map(el => el.file)
        //           ?.join("\n")}
        //       </span>
        //     );
        //   }
        // },

        {
          key: "q",
          title: "Ir",
          field: "actions",
          align: "center",
          width: 50,
          fixed: "right",
          renderBodyCell: ({ row }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  class="mr-3"
                  on-click={() => this.onItemRemove(row)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        }
      ];
      return [...common_cols];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.loading = true;
      this.showloadingspinner();
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "salesforce/sanciones";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.sanciones = resp.data;
      this.total_sanciones = resp.total;
      this.users_data = resp.users_data;
      this.loading = false;
      this.closeloadingspinner();
    },

    async downloadDocTemplate(id) {
      this.showloadingspinner();
      let fetch_url = ApiService.getdownloadsanciontemplateURL(id);
      this.isDownloadTemplate = true;
      console.log(fetch_url);
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "template.docx");
        this.isDownloadTemplate = false;
        this.closeloadingspinner();
      } catch (error) {
        this.closeloadingspinner();
      }
    },

    getThumbnailUri(file) {
      return ApiService.getThumbnailUri(file);
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    onPicture(img) {
      this.selected.materialPhoto.img = this.$refs["pictureInput"].file;
    },

    onItemShowImageDialog(image) {
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.selected_image = null;
        this.editedIndex = -1;
      });
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.showSancion = true;
      this.selectedTaskId = 1;
      // this.editedIndex = this.selected.id;
      // this.showEdit = true;
    },

    createSelectedModel(item) {
      const sancionEmpleado = _.get(item, "sancionEmpleado");
      const sancionResponsable = _.get(item, "sancionResponsable");
      const sancionDocumento = _.get(item, "sancionDocumento");
      const today = new Date().toISOString().split("T")[0];
      let model = {
        id: _.get(item, "id"),
        fecha_suceso: _.get(item, "fecha_suceso"),
        empleado: _.get(item, "empleado"),
        sancionEmpleado,
        sancionResponsable,
        sancionDocumento,
        responsable: _.get(item, "responsable"),
        gravedad: _.get(item, "gravedad"),
        categoria: _.get(item, "categoria"),
        sancion: _.get(item, "sancion"),
        comunicacion: _.get(item, "comunicacion"),
        fecha_alta: _.get(item, "fecha_alta"),
        fecha_comunicaion: _.get(item, "fecha_comunicaion"),
        status: _.get(item, "status"),
        descripcion: _.get(item, "descripcion"),
        comentarios: _.get(item, "comentarios"),
        documento_sancion: _.get(item, "documento_sancion"),
        doc_firmado: _.get(item, "doc_firmado"),
        sancionFirmado: _.get(item, "sancionFirmado")
      };
      if (!model.id) model.fecha_alta = today;
      if (!model.sancionFirmado?.length) model.sancionFirmado = [];
      return { ...model };
    },

    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId && this.deleteItemId.id > 0) {
        this.closeDelete();
        ApiService.delete(`salesforce/sanciones/${this.deleteItemId.id}`).then(
          () => {
            logInfo("Eliminada");
            this.getDataFromApi();
          }
        );
      }
    },

    async closeSancion(hasChanged) {
      console.log("hello ----------- ", hasChanged);
      this.showSancion = false;
      this.selectedTaskId = null;
      if (hasChanged) {
        await this.getDataFromApi();
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#sanciones_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    closeloadingspinner() {
      this.loadingInstance.close();
    },

    getNameFromTypes(_types, _key) {
      return Object.entries(_types).find(([key, value]) => key === _key)?.[1];
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td[col-key="logo"].ve-table-body-td {
  text-align: center !important;
}
</style>
